import { IonInput } from "@ionic/react";
import { ComponentProps, useEffect, useMemo, useRef, useState } from "react";
import { FieldError, FieldPath, FieldValues, Merge, UseFormRegisterReturn } from "react-hook-form";
import { useIntl } from "react-intl";

import styles from "@components/v1/fields/Input.module.css";
import RequiredFieldIndicator from "@components/v1/fields/RequiredFieldIndicator";
import InputLabel from "@components/v1/typography/InputLabel";
import CircleButton from "@components/v2/buttons/CircleButton";
import Icon from "@components/v2/icons/Icon";
import useAutofocus from "@hooks/useAutofocus";
import { generateRegisterEvent } from "@utils/formUtils";
import { IconName } from "@utils/iconUtils";

import "@theme/ion-input-overrides.css";

type InputActionType = ComponentProps<typeof CircleButton>;
type InputActionsType = [InputActionType, InputActionType?];

type Props<FormData extends FieldValues> = Omit<
  ComponentProps<typeof IonInput>,
  "size" | "className" | "value" | "name" | "mode"
> & {
  actions?: InputActionsType;
  emptyValue?: "" | null;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  icon?: IconName;
  noSpacing?: boolean;
  passedInRef?: React.RefObject<HTMLIonInputElement>;
  register: UseFormRegisterReturn<FieldPath<FormData>>;
  simpleInput?: boolean;
};

const Input = <FormData extends FieldValues>({
  actions,
  autofocus,
  emptyValue = "",
  error,
  icon,
  label,
  noSpacing,
  onIonBlur,
  onIonInput,
  register,
  required,
  simpleInput = false,
  ...inputProps
}: Props<FormData>) => {
  const inputRef = useRef<HTMLIonInputElement | null>(null);
  const intl = useIntl();
  const name = register.name;

  const arrayErrors = useMemo(() => {
    if (error === undefined) {
      return undefined;
    }

    if (!Array.isArray(error)) {
      return [error];
    }
    return error;
  }, [error]);

  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (arrayErrors && arrayErrors.length > 0) {
      setIsTouched(true);
    }
  }, [arrayErrors]);

  useAutofocus(!!autofocus, inputRef);

  useEffect(() => {
    if (inputProps.type === "number") {
      const current = inputRef.current;

      current?.addEventListener("wheel", e => {
        e.preventDefault();
      });
      return () => {
        current?.removeEventListener("wheel", e => {
          e.preventDefault();
        });
      };
    }
  }, [inputProps.type, inputRef]);

  const containerWrapperStyles = [styles.container];

  if (noSpacing) {
    containerWrapperStyles.push(styles.noMargin);
  }

  if (simpleInput) {
    containerWrapperStyles.push(styles.simpleInputContainer);
  }

  const inputStyles = [styles.input];

  if (simpleInput) {
    inputStyles.push(styles.simpleInput);
    inputStyles.push("simple-input");
  } else {
    inputStyles.push(styles.floatingInput);
  }

  if (inputRef.current?.value || inputProps.defaultValue) {
    inputStyles.push(styles.hasValue);
  }

  if (icon) {
    inputStyles.push(styles.withIcon);
  }

  if (arrayErrors && arrayErrors?.length > 0) {
    inputStyles.push("ion-invalid");
  }

  if (isTouched) {
    inputStyles.push("ion-touched");
  }

  if (actions && actions.length > 0) {
    inputStyles.push(styles[`withActions${actions.length}`]);
  }

  const floatingLabel = `${label ?? ""}${
    required ? " " + intl.formatMessage({ id: "dictionary.punctuation.asterisk" }) : ""
  }`;

  // we don't want the onBlur and onChange props applied automatically to the input since we are
  // manually triggering the change effects using the `onIonInput` prop
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onBlur, onChange, ...otherRegisterProps } = register;

  return (
    <div className={containerWrapperStyles.join(" ")}>
      <div className={styles.labelRow}>
        {label && simpleInput && (
          <div>
            <InputLabel htmlFor={name}>
              {label}
              {required && <RequiredFieldIndicator />}
            </InputLabel>
          </div>
        )}
        <div>
          {icon && (
            <div className={styles.iconWrapper}>
              <Icon color="medium" icon={icon} />
            </div>
          )}
        </div>
      </div>
      <IonInput
        aria-invalid={error?.message?.length ? "true" : "false"}
        autocomplete="off"
        className={inputStyles.join(" ")}
        errorText={arrayErrors?.[0]?.message}
        fill="solid"
        id={name}
        label={simpleInput ? undefined : floatingLabel}
        labelPlacement={simpleInput || !label ? undefined : "floating"}
        mode="md"
        onIonBlur={event => {
          setIsTouched(true);
          if (onIonBlur) onIonBlur(event);
        }}
        onIonInput={event => {
          onChange(generateRegisterEvent({ name, value: event.detail.value || emptyValue }));
          if (onIonInput) onIonInput(event);
        }}
        required={required}
        {...inputProps}
        {...otherRegisterProps}
        ref={e => {
          register.ref(e);
          inputRef.current = e;
        }}
      />
      {actions && actions.length > 0 && (
        <div className={styles.actions}>
          {actions.map(action => {
            if (!action) return;

            return (
              <div className={styles.action} key={action.ariaLabelKey}>
                <CircleButton disabled={inputProps.disabled} fill="clear" {...action} size="medium" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Input;
