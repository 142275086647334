import { ApolloClient, MutationHookOptions, OperationVariables, useMutation } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { DocumentNode } from "graphql";
import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";

import { errorContext } from "@context/Contexts";
import { ThingWithErrors } from "@utils/formUtils";

const useMutationWithValidation = <TData extends any, TVariables = OperationVariables>(
  document: DocumentNode | TypedDocumentNode<TData, TVariables>,
  txBase: string,
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [mutation, { client }] = useMutation<TData, TVariables>(document, options);
  const { addError } = useContext(errorContext);

  const intl = useIntl();

  const gatherValidationErrors = useCallback(
    async (variables: TVariables) =>
      mutation({
        variables
      }).then(res => {
        let useData: ThingWithErrors[] = [];
        if (res.data) {
          useData = [Object.values(res.data as object)?.[0]];
        }

        useData.forEach(data => {
          if (data?.errors && data?.errors?.length > 0) {
            data.errors.forEach(fieldError => {
              const messages = fieldError.errors.map(error =>
                intl.formatMessage({ id: `errors.${txBase}.${fieldError.field}.${error}` })
              );

              messages.forEach(message => {
                addError(message);
              });
            });
          }
        });

        return res.data;
      }),
    [addError, intl, mutation, txBase]
  );

  return [gatherValidationErrors, client] as [
    (variables: TVariables) => Promise<TData | null | undefined>,
    ApolloClient<object>
  ];
};

export default useMutationWithValidation;
