import { RefObject, useCallback, useEffect } from "react";

type Autofocusable = HTMLIonInputElement | HTMLIonSearchbarElement | HTMLTextAreaElement | HTMLDivElement;

const useAutofocus = (activate: boolean, inputRef: RefObject<Autofocusable>) => {
  const setFocus = useCallback((input: Autofocusable) => {
    if (input) {
      if ("setFocus" in input) {
        input.setFocus();
      } else if ("focus" in input) {
        input.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (activate && inputRef.current) {
      setFocus(inputRef.current);
    }
  }, [activate, inputRef, setFocus]);
};

export default useAutofocus;
