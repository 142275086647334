/*
 * this file is an overwrite of the native form element. it:
 * 1) disables native validations by default, b/c we want our form library to handle validation
 * 2) submits the form when the user performs an `Enter` keypress by default
 * 3) requires that you include an `onSubmit` prop, which is normally optional
 */
import { FormEvent, HTMLProps } from "react";

import useDebouncedSubmit from "@hooks/useDebouncedSubmit";

type Props = HTMLProps<HTMLFormElement> & {
  onSubmit?: (event: FormEvent<HTMLFormElement>) => Promise<void>;
};

const Form = ({ onSubmit, ...formProps }: Props) => {
  const onSubmitDebounced = useDebouncedSubmit({ onSubmit });

  return <form noValidate {...formProps} onSubmit={onSubmitDebounced} />;
};
export default Form;
