import { MouseEvent, useCallback } from "react";

import useLazyQueryWithPromise from "@hooks/useLazyQueryWithPromise";
import {
  FileResourceFragment,
  GetAttachmentDownloadDocument,
  GetAttachmentDownloadQuery,
  GetAttachmentDownloadQueryVariables
} from "@typing/Generated";
import { downloadFile } from "@utils/downloadUtils";

const useDownloadFileResource = (fileResource: FileResourceFragment) => {
  const attachmentFetch = useLazyQueryWithPromise<GetAttachmentDownloadQuery, GetAttachmentDownloadQueryVariables>(
    GetAttachmentDownloadDocument
  );

  const handleDownload = useCallback(
    (event: MouseEvent<HTMLIonRouterLinkElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (fileResource.attachment?.id) {
        attachmentFetch({ fetchPolicy: "no-cache", variables: { id: fileResource.attachment.id } }).then(response => {
          if (response.data.attachmentDownload.url && response.data.attachmentDownload.filename) {
            downloadFile(response.data.attachmentDownload.url, response.data.attachmentDownload.filename);
          }
        });
      }
    },
    [attachmentFetch, fileResource.attachment?.id]
  );

  return handleDownload;
};

export default useDownloadFileResource;
