import { FormEvent, useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

type UseDebouncedSubmitArguments = {
  onSubmit?: (event: FormEvent<HTMLFormElement>) => Promise<void>;
};

const useDebouncedSubmit = ({ onSubmit }: UseDebouncedSubmitArguments) => {
  const onSubmitDebounced = useDebouncedCallback((event: FormEvent<HTMLFormElement>) => onSubmit?.(event), 500, {
    leading: true
  });

  const onSubmitDebouncedCallback = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmitDebounced(event);
    },
    [onSubmitDebounced]
  );

  useEffect(
    () => () => {
      onSubmitDebounced.flush();
    },
    [onSubmitDebounced]
  );

  return onSubmitDebouncedCallback;
};

export default useDebouncedSubmit;
