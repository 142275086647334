import classnames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "@components/v2/overlays/Tooltip.module.css";
import useOpener from "@hooks/useOpener";
import useTimeout from "@hooks/useTimeout";
import { Colors } from "@utils/colorUtils";
import { generateHTMLId } from "@utils/htmlUtils";

type Direction = "top" | "bottom" | "left" | "right";

type TooltipTriggerProps = {
  "aria-describedby"?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  role?: string;
  tabIndex?: 0;
};

type Props = {
  arrowPosition?: "center" | "end" | "start" | "none";
  children: (props: TooltipTriggerProps) => React.ReactNode;
  color?: Colors;
  content?: React.ReactNode;
  delay?: number;
  expand?: boolean;
  initialDirection?: Direction;
  offsetTop?: number;
};

const Tooltip = ({
  arrowPosition = "center",
  children,
  color = "teal-400",
  content,
  delay = 300,
  expand = false,
  initialDirection = "top",
  offsetTop = 0
}: Props) => {
  const { addTimeout, clearTimeouts } = useTimeout();
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const activeOpener = useOpener();
  const [direction, setDirection] = useState<Direction>(initialDirection);
  const [positionStyles, setPositionStyles] = useState({ left: 0, top: 0 });

  const showTip = () => {
    if (activeOpener.isOpen) return;

    addTimeout(() => {
      activeOpener.open();
    }, delay);
  };

  const hideTip = () => {
    if (tooltipRef.current?.contains(document.activeElement)) {
      return;
    }
    clearTimeouts();
    activeOpener.close();
  };

  const getHorizontalCenter = (triggerRect: DOMRect, tooltipRect: DOMRect): number =>
    triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;

  const getVerticalCenter = (triggerRect: DOMRect, tooltipRect: DOMRect): number =>
    triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;

  const calculatePosition = useCallback(
    (triggerRect: DOMRect, tooltipRect: DOMRect, newDirection: Direction): { left: number; top: number } => {
      const horizontalCenter = getHorizontalCenter(triggerRect, tooltipRect);
      const verticalCenter = getVerticalCenter(triggerRect, tooltipRect);
      const arrowOffset = 4;

      switch (newDirection) {
        case "top":
          return { left: horizontalCenter, top: triggerRect.top - tooltipRect.height - offsetTop - arrowOffset };
        case "bottom":
          return { left: horizontalCenter, top: triggerRect.bottom - offsetTop + arrowOffset };
        case "left":
          return { left: triggerRect.left - tooltipRect.width - 10, top: verticalCenter - offsetTop };
        case "right":
          return { left: triggerRect.right + 10, top: verticalCenter - offsetTop };
        default:
          return { left: 0, top: 0 - offsetTop };
      }
    },
    [offsetTop]
  );

  const isOverflowing = useCallback(
    (direction: Direction, triggerRect: DOMRect, tooltipRect: DOMRect): boolean => {
      const position = calculatePosition(triggerRect, tooltipRect, direction);
      return (
        position.left < 0 ||
        position.top < 0 ||
        position.left + tooltipRect.width > window.innerWidth ||
        position.top + tooltipRect.height > window.innerHeight
      );
    },
    [calculatePosition]
  );

  // Each initial direction has a specific preferred order to flow through to find a way to position
  // the tooltip so it doesn't overflow the screen. If the tooltip overflows the screen at every
  // direction, it will resolve to the final direction in the order.
  const tryNextDirectionOnWindowOverflow = useCallback(() => {
    const directionOrder: Record<Direction, Direction[]> = {
      bottom: ["bottom", "top", "left", "right"],
      left: ["left", "right", "top", "bottom"],
      right: ["right", "left", "top", "bottom"],
      top: ["top", "bottom", "left", "right"]
    };

    const triggerRect = triggerRef.current?.getBoundingClientRect();
    const tooltipRect = tooltipRef.current?.getBoundingClientRect();
    if (!triggerRect || !tooltipRect) return;

    const order = directionOrder[initialDirection];
    const newDirectionIndex = order.indexOf(direction) + 1;
    if (isOverflowing(direction, triggerRect, tooltipRect) && newDirectionIndex < order.length) {
      const newDirection = order[newDirectionIndex];
      setDirection(newDirection);
    }
  }, [direction, initialDirection, isOverflowing]);

  // The tryNextDirectionOnWindowOverflow function will re-render when the direction changes,
  // triggering this effect. So, each time it triggers a direction change, it will recursively
  // execute itself again via this effect to check if the tooltip is still overflowing, and whether
  // the direction should be updated again.
  useEffect(() => {
    tryNextDirectionOnWindowOverflow();
  }, [activeOpener.isOpen, tryNextDirectionOnWindowOverflow]);

  useEffect(() => {
    const resizeListener = () => {
      // On window re-size, the initial direction may be valid again, but the tooltip direction may have
      // already moved to subsequent steps in the order based on the original window size.
      setDirection(initialDirection);
      // If the setDirection above actually _changes_ the direction, then
      // tryNextDirectionOnWindowOverflow would be executed automatically via the effect above. If
      // it doesn't change the direction, then we need to manually call it here, and there's little
      // harm in manually calling it either way.
      tryNextDirectionOnWindowOverflow();
    };

    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [initialDirection, tryNextDirectionOnWindowOverflow]);

  useEffect(() => {
    if (tooltipRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      if (!triggerRect || !tooltipRect) return;

      const position = calculatePosition(triggerRect, tooltipRect, direction);
      setPositionStyles(position);
    }
  }, [activeOpener.isOpen, calculatePosition, direction]);

  const [id] = useState<string>(generateHTMLId());

  return (
    // The mouse events are required to ensure you can mouse over and highlight text in the tooltip,
    // which is required WCAG functionality. They aren't intended to imply that the wrapper is
    // interactive.
    //
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames("component-Tooltip", styles.tooltipWrapper, expand && "block")}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      <div ref={triggerRef}>
        {children({
          "aria-describedby": id,
          onBlur: hideTip,
          onFocus: showTip,
          role: "button",
          tabIndex: 0
        })}
      </div>

      {activeOpener.isOpen && (
        <>
          {createPortal(
            <div
              aria-live="polite"
              className={classnames(styles.tip, styles[direction], styles[`arrow-${arrowPosition}`])}
              id={id}
              ref={tooltipRef}
              role="tooltip"
              style={{
                ...positionStyles,
                backgroundColor: `var(--ion-color-${color})`,
                color: `var(--ion-color-${color})`
              }}
            >
              <span className={styles.tipContent} style={{ color: `var(--ion-color-${color}-contrast)` }}>
                {content}
              </span>
            </div>,
            document.body
          )}
        </>
      )}
    </div>
  );
};

export default Tooltip;
