import { PropsWithChildren, useEffect } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

type Props<FormData extends FieldValues> = PropsWithChildren<{
  condition: boolean | null;
  fieldName: Path<FormData>;
  form: UseFormReturn<FormData>;
}>;

const FormCondition = <FormData extends FieldValues>({ children, condition, fieldName, form }: Props<FormData>) => {
  useEffect(() => {
    if (condition) {
      form.register(fieldName);
    } else {
      form.unregister(fieldName, { keepValue: true });
    }
  }, [condition, fieldName, form]);

  return condition ? children : null;
};

export default FormCondition;
