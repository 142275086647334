import { IonItem, IonLabel, IonList } from "@ionic/react";
import { ComponentProps, PropsWithChildren } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Modal from "@components/v1/Modal";
import FileResourceCard from "@components/v1/trackers/FileResourceCard";
import itemStyles from "@components/v1/trackers/TrackedItemLine.module.css";
import styles from "@components/v1/trackers/TrackerItemModal.module.css";
import BodyCopy from "@components/v1/typography/BodyCopy";
import ButtonLink from "@components/v1/typography/ButtonLink";
import Heading2 from "@components/v1/typography/Heading2";
import IconBase from "@components/v2/icons/IconBase";
import { LegalDocumentFragment } from "@typing/Generated";
import { IconName } from "@utils/iconUtils";
import { isMobile } from "@utils/platformUtils";

type Props = Pick<ComponentProps<typeof Modal>, "opener"> &
  PropsWithChildren<{
    actions?: {
      handler: () => void;
      icon: IconName;
      labelKey: string;
    }[];
    fileResources?: LegalDocumentFragment["fileResources"];
    notes: string | null;
    subtitle: string;
    title: string;
  }>;

const TrackerItemModal = ({ actions, children, fileResources, notes, subtitle, title, ...modalProps }: Props) => {
  const intl = useIntl();
  const mobileModalProps: Pick<ComponentProps<typeof Modal>, "breakpoints" | "handle" | "initialBreakpoint"> = {};
  if (isMobile()) {
    mobileModalProps.handle = false;
    mobileModalProps.breakpoints = [0, 0.9, 1];
    mobileModalProps.initialBreakpoint = 0.9;
  }

  const divider = <hr className="margin-10-top margin-10-bottom" color="gray-100" />;

  return (
    <Modal className={styles.modal} contentClassName="padding-20" hideHeader {...mobileModalProps} {...modalProps}>
      {() => (
        <>
          <div className="flex justify-end">
            <ButtonLink
              aria-label={intl.formatMessage({ id: "dictionary.close" })}
              className="flex"
              onClick={modalProps.opener.close}
              stylized={false}
            >
              <IconBase color="gray-200" icon="xmark" size="medium" />
            </ButtonLink>
          </div>
          <div className={`${styles.heading} align-center`}>
            <Heading2 bold className="margin-10-bottom margin-10-top" color="black">
              {title}
            </Heading2>
            <BodyCopy bold color="gray" size="small">
              {subtitle}
            </BodyCopy>
          </div>
          {children}
          {notes && (
            <>
              {divider}
              <IonList className="ion-no-padding">
                <IonItem className={itemStyles.item} lines="none">
                  <IonLabel>
                    <Heading2 bold color="dark">
                      <FormattedMessage id="dictionary.notes" />
                    </Heading2>
                    <BodyCopy color="dark" size="small">
                      {notes}
                    </BodyCopy>
                  </IonLabel>
                </IonItem>
              </IonList>
            </>
          )}
          {fileResources && fileResources.length > 0 && (
            <>
              {divider}
              <IonItem className={itemStyles.item} lines="none">
                <IonLabel>
                  <Heading2 bold color="dark">
                    <FormattedMessage id="dictionary.attachments" />
                  </Heading2>
                  {fileResources.map(fileResource => (
                    <FileResourceCard fileResource={fileResource} key={fileResource.id} />
                  ))}
                </IonLabel>
              </IonItem>
            </>
          )}
          {actions && actions.length > 0 && (
            <>
              {divider}
              <IonList className="ion-no-padding">
                {actions.map(action => (
                  <IonItem
                    button
                    className={itemStyles.item}
                    key={action.labelKey}
                    lines="none"
                    onClick={() => {
                      action.handler();
                    }}
                  >
                    <IconBase
                      className="margin-4-top margin-4-bottom margin-10-right"
                      color="gray-200"
                      icon={action.icon}
                      size="medium"
                      slot="start"
                    />
                    <BodyCopy color="dark" size="small">
                      <FormattedMessage id={action.labelKey} />
                    </BodyCopy>
                  </IonItem>
                ))}
              </IonList>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default TrackerItemModal;
