import { Reference } from "@apollo/client";

import useMutationWithValidation from "@hooks/useMutationWithValidation";
import {
  LegalDocumentDestroyDocument,
  LegalDocumentDestroyMutation,
  LegalDocumentDestroyMutationVariables
} from "@typing/Generated";

const useDestroyLegalDocument = (journeyId: string) => {
  const [mutation] = useMutationWithValidation<LegalDocumentDestroyMutation, LegalDocumentDestroyMutationVariables>(
    LegalDocumentDestroyDocument,
    "legalDocument",
    {
      update: (store, { data }) => {
        if (data?.legalDocumentDestroy) {
          store.modify({
            fields: {
              legalDocuments(cachedLegalDocumentRefs: readonly Reference[], { readField }) {
                return cachedLegalDocumentRefs.filter(
                  legalDocumentRef => data.legalDocumentDestroy?.id !== readField("id", legalDocumentRef)
                );
              }
            },
            id: store.identify({ __typename: "Journey", id: journeyId })
          });
        }
      }
    }
  );

  return mutation;
};

export default useDestroyLegalDocument;
