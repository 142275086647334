import Autolinker from "autolinker";
import DOMPurify from "dompurify";
import parse, { DOMNode, domToReact, Element } from "html-react-parser";
import { HTMLProps } from "react";

import ExternalLink from "@components/v1/typography/ExternalLink";
import InternalLink from "@components/v1/typography/InternalLink";
import { GetMeQuery } from "@typing/Generated";
import { replaceAll } from "@utils/stringUtils";

type MyExpert = Extract<GetMeQuery["me"], { __typename: "Expert" }>;

export const generateHTMLId = () => Math.random().toString(36).slice(2);

type Args = {
  handleLinkClick: (link: string, internal: boolean) => void;
  journeyId?: string | null;
  myExpert: MyExpert | null | undefined;
  rawHtml: string | null | undefined;
};

export const sanitizeHtmlHelper = ({ handleLinkClick, journeyId, myExpert, rawHtml }: Args) => {
  if (rawHtml) {
    // Strip out unwelcome tags.
    const sanitized = DOMPurify.sanitize(rawHtml);

    const replaced = journeyId ? replaceAll(sanitized, "JOURNEY_ID", journeyId) : sanitized;

    const handleReplacement = (domNode: DOMNode) => {
      if (domNode instanceof Element && domNode.name === "a") {
        let href = domNode.attribs.href;

        // There's a bug where somehow the rich text editor is wrapping links in an anchor tag with no href.
        // If that's the case, we want to grab the data from the first child and use it as the href. Here is an example faulty domNode:
        // {
        //   attribs: {
        //     href: undefined,
        //     target: '_blank',
        //     rel: 'noopener noreferrer',
        //   }
        //   children: [{ data: "https://momentumforhealth.org/services/residential/adult-residential/", type: "text" }]
        // }
        if (!href && domNode.children.length > 0) {
          href = (domNode.children[0] as any).data;

          domNode.attribs.href = href;
        }

        const appDomain = import.meta.env.VITE_APP_HOST;

        // If the anchor tag is on the current domain, we want to return a router Link so that it uses the
        // router to render only what we need instead of re-rendering the whole page. If it's on a different
        // domain, add the attributes to make it open in a separate tab.
        // The FAQ page is unique and should be opened in its own tab
        if (href?.includes(appDomain) && !href.endsWith("/faq")) {
          const hrefWithRoute = myExpert ? href.replace("/client/", "/expert/") : href;
          return (
            <InternalLink
              linkEventCreateVariables={{ journeyId }}
              onClick={() => {
                handleLinkClick(hrefWithRoute, true);
              }}
              to={hrefWithRoute.replace(appDomain, "")}
            >
              {domToReact(domNode.children as DOMNode[])}
            </InternalLink>
          );
        } else {
          return (
            <ExternalLink
              href={href}
              linkEventCreateVariables={{ journeyId }}
              onClick={() => {
                handleLinkClick(href, false);
              }}
            >
              {domToReact(domNode.children as DOMNode[])}
            </ExternalLink>
          );
        }
      }

      return domNode;
    };

    // Then autolink urls that are not already in anchor tags and produce an element to inject into the DOM.
    return parse(Autolinker.link(replaced), { replace: handleReplacement });
  }
  return "";
};

type ButtonLikeElementProps = (
  onClick?: (e: { nativeEvent: Event }) => void
) => Pick<HTMLProps<HTMLDivElement | HTMLTableCellElement>, "onClick" | "onKeyDown" | "role" | "tabIndex">;

export const buttonLikeElementProps: ButtonLikeElementProps = onClick => ({
  onClick,
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") onClick?.(e);
  },
  role: "button",
  tabIndex: 0
});
