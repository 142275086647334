import classNames from "classnames";
import { PropsWithChildren } from "react";

import styles from "@components/v1/typography/InputLabel.module.css";

type Props = PropsWithChildren<{
  className?: string;
  htmlFor?: string;
  labelPlacement?: "stacked";
}>;

const InputLabel = ({ children, className, htmlFor, labelPlacement }: Props) => (
  <label
    className={classNames(styles.inputLabel, labelPlacement === "stacked" && styles.stackedInputLabel, className)}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export default InputLabel;
