import { IonCard, IonCardContent, IonCardHeader } from "@ionic/react";
import { ComponentProps, JSX, ReactNode } from "react";

import styles from "@components/v1/cards/CardBase.module.css";
import { Colors } from "@utils/colorUtils";

type Props = Omit<ComponentProps<typeof IonCard>, "button" | "children" | "color"> & {
  body: ReactNode;
  bodyPadding?: boolean;
  border?: boolean;
  color?: Colors;
  contentClassName?: string;
  header?: ReactNode;
  image?: JSX.IntrinsicElements["img"];
  rounding?: "none" | "small" | "default" | "large";
  selected?: boolean;
  shadow?: boolean;
};

const CardBase = ({
  body,
  bodyPadding = true,
  border = true,
  className,
  color = "white",
  contentClassName,
  header,
  image,
  rounding = "default",
  selected = false,
  shadow = false,
  ...ionCardProps
}: Props) => {
  const classNames = [styles.card, styles[`rounding-${rounding}`]];
  if (className) classNames.push(className);
  if (border) classNames.push(styles.border);
  if (selected) classNames.push(styles.selected);
  if (shadow) classNames.push(styles.shadow);

  const isButton = !selected && ionCardProps.routerLink === undefined && ionCardProps.onClick !== undefined;

  const contentClassNames = [];
  if (contentClassName) contentClassNames.push(contentClassName);
  if (!bodyPadding) contentClassNames.push("ion-no-padding");

  return (
    <IonCard {...ionCardProps} button={isButton} className={classNames.join(" ")} color={color}>
      {image && <img alt="" {...image} />}
      {header && <IonCardHeader>{header}</IonCardHeader>}
      <IonCardContent className={contentClassNames.join(" ")}>{body}</IonCardContent>
    </IonCard>
  );
};

export default CardBase;
