import { FieldError } from "react-hook-form";

import FormError from "@components/v1/typography/FormError";

type Props = {
  error: FieldError | undefined;
};

const Error = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <div>
      <FormError>{error.message}</FormError>
    </div>
  );
};

export default Error;
