import { SetStateAction } from "react";
import { FormattedMessage } from "react-intl";

import styles from "@components/v1/fields/FormButtons.module.css";
import Button from "@components/v2/buttons/Button";
import { BackOrNextEnum } from "@typing/Enums";

type FormState = {
  formState: {
    isSubmitting: boolean;
    isValid: boolean;
  };
  reset: () => void;
};

type Props = {
  backDisabled: boolean;
  hookForm?: FormState;
  saveDisabled?: boolean;
  saveKey?: string;
  setBackOrNext?: (value: SetStateAction<BackOrNextEnum | null>) => void;
  skipValid?: boolean;
};

const BackAndNextFormButtons = ({
  backDisabled,
  hookForm,
  saveDisabled = false,
  saveKey,
  setBackOrNext,
  skipValid = false
}: Props) => (
  <div className={styles.backAndNextFooter}>
    <Button
      disabled={
        (!hookForm?.formState.isValid && !skipValid) || hookForm?.formState.isSubmitting || saveDisabled || backDisabled
      }
      expand="block"
      fill="outline"
      onClick={
        setBackOrNext
          ? () => {
              setBackOrNext(BackOrNextEnum.BACK);
            }
          : undefined
      }
      type="submit"
    >
      <FormattedMessage id="models.assessments.goBack" />
    </Button>
    <Button
      data-test="submit-button"
      disabled={(!hookForm?.formState.isValid && !skipValid) || hookForm?.formState.isSubmitting || saveDisabled}
      expand="block"
      onClick={
        setBackOrNext
          ? () => {
              setBackOrNext(BackOrNextEnum.NEXT);
            }
          : undefined
      }
      type="submit"
    >
      <FormattedMessage id={saveKey ? saveKey : "models.assessments.nextQuestion"} />
    </Button>
  </div>
);

export default BackAndNextFormButtons;
