import { IonList } from "@ionic/react";

import FormError from "@components/v1/typography/FormError";

type Props = {
  errors: string[];
};

const BaseErrors = ({ errors }: Props) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <IonList>
      {errors.map(message => (
        <div className="contentSpacing" key={message}>
          <FormError>{message}</FormError>
        </div>
      ))}
    </IonList>
  );
};

export default BaseErrors;
