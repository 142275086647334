import { FormattedDate, FormattedMessage } from "react-intl";

import Nbsp from "@components/v1/Nbsp";

type PropsBasic = {
  mmddyyyy?: boolean;
  showWeekday?: boolean;
  showYear?: boolean;
  value: Date | string;
  weekday?: "short" | "long" | "narrow";
};

type PropsWithTimezone = PropsBasic & {
  absoluteDate?: undefined;
  timezone?: string;
};

type PropsWithAbsoluteDate = PropsBasic & {
  absoluteDate?: boolean;
  timezone?: undefined;
};

type Props = PropsWithTimezone | PropsWithAbsoluteDate;

const DateStamp = ({
  absoluteDate = false,
  mmddyyyy = false,
  showWeekday = true,
  showYear = true,
  timezone,
  value,
  weekday = "short"
}: Props) => {
  if (mmddyyyy) {
    return (
      <FormattedDate
        day="2-digit"
        month="2-digit"
        timeZone={absoluteDate ? "utc" : timezone}
        value={value}
        year="2-digit"
      />
    );
  }

  return (
    <>
      <FormattedDate
        day="numeric"
        month="short"
        timeZone={absoluteDate ? "utc" : timezone}
        value={value}
        weekday={showWeekday ? weekday : undefined}
      />
      {showYear && (
        <>
          <FormattedMessage id="dictionary.punctuation.comma" />
          <Nbsp />
          <FormattedDate timeZone={absoluteDate ? "utc" : timezone} value={value} year="numeric" />
        </>
      )}
    </>
  );
};

export default DateStamp;
