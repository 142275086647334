import { FormattedMessage } from "react-intl";

import styles from "@components/v1/fields/RequiredFieldIndicator.module.css";

type Props = {
  inheritSize?: boolean;
};

const RequiredFieldIndicator = ({ inheritSize = false }: Props) => (
  <span className={`${styles.requiredFieldIndicator} ${inheritSize ? styles.inheritSize : ""}`}>
    <FormattedMessage id="dictionary.punctuation.asterisk" />
  </span>
);

export default RequiredFieldIndicator;
