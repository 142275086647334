import { DocumentNode, OperationVariables, QueryOptions, TypedDocumentNode, useApolloClient } from "@apollo/client";

// export declare function useLazyQuery<TData = any, TVariables = OperationVariables>(
//   query: DocumentNode | TypedDocumentNode<TData, TVariables>,
//   options?: LazyQueryHookOptions<TData, TVariables>): QueryTuple<TData, TVariables>;

type QueryOptionsWithoutQuery<TVariables = OperationVariables, TData = any> = Omit<
  QueryOptions<TVariables, TData>,
  "query"
>;

const useLazyQueryWithPromise = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
) => {
  const apollo = useApolloClient();

  return async (options?: QueryOptionsWithoutQuery<TVariables, TData>) =>
    apollo.query<TData, TVariables>({ query, ...options });
};

export default useLazyQueryWithPromise;
