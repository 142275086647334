import { IonButton } from "@ionic/react";
import { ComponentProps } from "react";

import useMutationWithValidation from "@hooks/useMutationWithValidation";
import { LinkEventCreateDocument, LinkEventCreateMutation, LinkEventCreateMutationVariables } from "@typing/Generated";
import { fullInternalUrl } from "@utils/linkUtils";

type Props = Pick<ComponentProps<typeof IonButton>, "href" | "onClick" | "routerLink"> & {
  linkEventCreateVariables?: Omit<LinkEventCreateMutationVariables, "link">;
};

const useHandleClick = ({ href, linkEventCreateVariables, onClick, routerLink }: Props) => {
  const [linkEventCreate] = useMutationWithValidation<LinkEventCreateMutation, LinkEventCreateMutationVariables>(
    LinkEventCreateDocument,
    "linkEvent"
  );

  const handleClick: Props["onClick"] = e => {
    if (onClick) onClick(e);
    if (href) linkEventCreate({ ...linkEventCreateVariables, link: href });
    if (routerLink) linkEventCreate({ ...linkEventCreateVariables, link: fullInternalUrl(routerLink) });
  };

  return handleClick;
};

export default useHandleClick;
