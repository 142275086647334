import { IonButton } from "@ionic/react";
import classnames from "classnames";
import { ComponentProps, Ref } from "react";

import styles from "@components/v2/buttons/Button.module.css";
import useHandleClick from "@hooks/useHandleClick";
import { LinkEventCreateMutationVariables } from "@typing/Generated";
import { Colors } from "@utils/colorUtils";

type Props = Omit<ComponentProps<typeof IonButton>, "color" | "mode" | "shape" | "size"> & {
  color?: Colors;
  forwardRef?: Ref<HTMLIonButtonElement>;
  inline?: boolean;
  linkEventCreateVariables?: Omit<LinkEventCreateMutationVariables, "link">;
  resizeOnMobile?: boolean;
  size?: Extract<ComponentProps<typeof IonButton>["size"], "default" | "large">;
  variant?: "primary" | "secondary" | "tertiary";
};

const variantDefs = {
  link: {
    color: "teal-500",
    fill: "clear"
  },
  primary: {
    color: "teal-500",
    fill: "solid"
  },
  secondary: {
    color: "teal-500",
    fill: "outline"
  },
  tertiary: {
    color: "teal-500",
    fill: "clear"
  }
};

const Button = ({
  className,
  color = "teal-500",
  forwardRef,
  inline,
  linkEventCreateVariables = {},
  onClick,
  resizeOnMobile = true,
  size,
  variant,
  ...props
}: Props) => {
  if (variant) {
    color = variantDefs[variant].color as Colors;
    props.fill = variantDefs[variant].fill as "solid" | "outline" | "clear";
  }
  // change outline to solid if disabled
  if (props.fill === "outline" && props.disabled) {
    props.fill = "solid";
  }

  const handleClick = useHandleClick({ ...props, linkEventCreateVariables, onClick });
  return (
    // eslint-disable-next-line react/forbid-elements
    <IonButton
      color={color}
      {...props}
      className={classnames(
        "component-Button",
        styles.button,
        className && className,
        size && styles[size],
        resizeOnMobile && styles.resizeOnMobile,
        inline && styles.inline
      )}
      mode="md"
      onClick={handleClick}
      ref={forwardRef}
      shape="round"
    />
  );
};
export default Button;
