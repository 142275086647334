import { IonLabel } from "@ionic/react";
import { ComponentProps } from "react";

import styles from "@components/v1/labels/SmallLabel.module.css";

type Props = ComponentProps<typeof IonLabel>;

const SmallLabel = ({ children, ...labelProps }: Props) => (
  <IonLabel className={styles.smallLabel} position="stacked" {...labelProps}>
    {children}
  </IonLabel>
);

export default SmallLabel;
