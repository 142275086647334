import { useEffect, useRef } from "react";

const useTimeout = () => {
  const timeouts = useRef<NodeJS.Timeout[]>([]);

  const clearTimeouts = () => {
    timeouts.current.forEach(timeout => {
      clearTimeout(timeout);
    });
    timeouts.current = [];
  };

  useEffect(
    () => () => {
      clearTimeouts();
    },
    []
  );

  const removeTimeout = (timeout: NodeJS.Timeout) => {
    timeouts.current = timeouts.current.filter(t => t !== timeout);
  };

  const addTimeout = (callback: () => void, duration: number) => {
    // eslint-disable-next-line no-restricted-globals
    const timeout = setTimeout(() => {
      callback();
      removeTimeout(timeout);
    }, duration);
    timeouts.current.push(timeout);
    return timeout;
  };

  return { addTimeout, clearTimeouts, removeTimeout, timeouts };
};

export default useTimeout;
