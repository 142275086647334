import { IonList } from "@ionic/react";
import { ChangeEvent, ReactNode } from "react";
import { Control, Controller, FieldError, FieldPath, FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import Error from "@components/v1/fields/Error";
import styles from "@components/v1/fields/RadioButtonGroup.module.css";
import RequiredFieldIndicator from "@components/v1/fields/RequiredFieldIndicator";
import SmallLabel from "@components/v1/labels/SmallLabel";
import BodyCopy from "@components/v1/typography/BodyCopy";
import InputLabel from "@components/v1/typography/InputLabel";
import { generateHTMLId } from "@utils/htmlUtils";
import { ImageName, imageUrl } from "@utils/imageUtils";

type RadioOption = {
  image?: ImageName;
  label: string;
  number?: number;
  value: string | null;
};

type Props<FormData extends FieldValues> = {
  allowDeselection?: boolean;
  control: Control<FormData>;
  disabled?: boolean;
  error: FieldError | undefined;
  fullWidth?: boolean;
  inTracker?: boolean;
  label?: string | ReactNode;
  name: FieldPath<FormData>;
  noSpacing?: boolean;
  options: RadioOption[];
  required?: boolean;
  smaller?: boolean;
  subLabel?: string | ReactNode;
  withImage?: boolean;
};

const RadioButtonGroup = <FormData extends FieldValues>({
  allowDeselection = false,
  control,
  disabled = false,
  error,
  fullWidth = false,
  inTracker = false,
  label,
  name,
  noSpacing = false,
  options,
  required = false,
  smaller = false,
  subLabel,
  withImage = false
}: Props<FormData>) => {
  const intl = useIntl();

  return (
    <fieldset className={styles.radioGroup}>
      <div className={noSpacing ? "" : styles.labelSpacing}>
        {label && (
          <div className={inTracker ? styles.trackerLabel : ""} id={`${name}-label`}>
            <InputLabel>
              {label}
              {required && <RequiredFieldIndicator />}
            </InputLabel>
          </div>
        )}
        {subLabel && (
          <div className={inTracker ? styles.centeredLabel : ""} id={`${name}-sublabel`}>
            <SmallLabel>
              {subLabel}
              {required && subLabel && !label && <RequiredFieldIndicator />}
            </SmallLabel>
          </div>
        )}
      </div>
      <div className={styles.options}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <IonList
              aria-describedby={error ? `${name}-error` : ""}
              aria-invalid={error?.message?.length ? "true" : "false"}
              aria-labelledby={label ? `${name}-label` : subLabel ? `${name}-sublabel` : ""}
              aria-required={required}
              className={`${styles.list} ${inTracker ? styles.trackerList : ""}`}
            >
              {options.map(option => {
                const id = `${option.value}-${generateHTMLId()}`;
                return (
                  <div
                    className={`${option.value === value ? styles.checked : styles.notChecked} ${
                      smaller ? styles.lessMargin : ""
                    } ${disabled ? styles.disabled : ""} ${withImage ? styles.withImage : ""} ${
                      fullWidth ? styles.fullWidth : ""
                    }`}
                    data-test="radio-option"
                    key={`${option.label}-${option.value ?? "null"}`}
                  >
                    <input
                      aria-label={option.label ?? undefined}
                      checked={option.value === value}
                      className={styles.input}
                      disabled={disabled}
                      id={id}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        if (allowDeselection && !event.target.checked) {
                          onChange(null);
                        } else if (event.target.value === "") {
                          const emptyOption = options.find(option => !option.value);
                          onChange(emptyOption?.value);
                        } else {
                          onChange(event.target.value);
                        }
                      }}
                      type="radio"
                      value={option.value ?? undefined}
                    />
                    <label className={styles.optionLabel} htmlFor={id}>
                      <div className={styles.imageAndLabel}>
                        {option.image && (
                          <div className={styles.labelImage}>
                            <img
                              alt={intl.formatMessage({ id: "accessibility.altTags.whiteLogo" })}
                              src={imageUrl(option.image)}
                            />
                          </div>
                        )}
                        <div className={styles.optionLabel}>
                          {option.number !== undefined && (
                            <div>
                              <BodyCopy color={option.value === value ? "white" : "gray-300"}>{option.number}</BodyCopy>
                            </div>
                          )}
                          <BodyCopy color={option.value === value ? "white" : "gray-300"}>{option.label}</BodyCopy>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })}
            </IonList>
          )}
        />
      </div>
      {error && (
        <div id={`${name}-error`}>
          <Error error={error} />
        </div>
      )}
    </fieldset>
  );
};

export default RadioButtonGroup;
