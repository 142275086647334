import { Reference, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

import BackAndNextFormButtons from "@components/v1/fields/BackAndNextFormButtons";
import BaseErrors from "@components/v1/fields/BaseErrors";
import DateInput from "@components/v1/fields/DateInput";
import DirectUploadFileInput from "@components/v1/fields/DirectUploadFileInput";
import FormButtons from "@components/v1/fields/FormButtons";
import Input from "@components/v1/fields/Input";
import RadioButtonGroup from "@components/v1/fields/RadioButtonGroup";
import Select from "@components/v1/fields/Select";
import Form from "@components/v1/forms/Form";
import FormCondition from "@components/v1/forms/FormCondition";
import styles from "@components/v1/forms/LegalDocumentForm.module.css";
import CircleButton from "@components/v2/buttons/CircleButton";
import useFormBackOrNext from "@hooks/useFormBackOrNext";
import { BackOrNextEnum } from "@typing/Enums";
import {
  FileResourceFragmentDoc,
  LegalDocumentCreateDocument,
  LegalDocumentCreateMutation,
  LegalDocumentCreateMutationVariables,
  LegalDocumentFragment,
  LegalDocumentFragmentDoc,
  LegalDocumentKindEnum,
  LegalDocumentStatusEnum,
  LegalDocumentUpdateDocument,
  LegalDocumentUpdateMutation,
  LegalDocumentUpdateMutationVariables
} from "@typing/Generated";
import { handleMutationResponse } from "@utils/formUtils";
import { getFileResourceTitle } from "@utils/modelUtils";
import { optionsForLegalDocumentKind, optionsForLegalDocumentStatus } from "@utils/optionUtils";
import { dateToMDYString } from "@utils/timeUtils";

type FormData = {
  directUploadBlobIds: string[];
  fileResourceIds: string[];
  kind?: LegalDocumentKindEnum | null;
  notes: string | null;
  status: LegalDocumentStatusEnum | null;
  storageLocation: string | null;
  updateDate: string | null;
};

type Props = {
  backDisabled?: boolean;
  defaultAsComplete?: boolean;
  inTracker?: boolean;
  journeyId: string;
  kindOptions: LegalDocumentKindEnum[];
  legalDocument?: LegalDocumentFragment;
  messageKey?: string;
  onCancel?: () => void;
  onError?: () => void;
  onSuccess: () => void;
  setBackOrNext?: (value: SetStateAction<BackOrNextEnum | null>) => void;
  titleKey?: string;
  uploadOnly?: boolean;
};

const LegalDocumentForm = ({
  backDisabled = false,
  defaultAsComplete = false,
  inTracker = false,
  journeyId,
  kindOptions,
  legalDocument,
  messageKey,
  onCancel,
  onError,
  onSuccess,
  setBackOrNext,
  titleKey,
  uploadOnly = false
}: Props) => {
  const intl = useIntl();

  let defaultUpdateDate = null;
  if (legalDocument?.updateDate) {
    defaultUpdateDate = dateToMDYString(legalDocument.updateDate);
  }

  const defaultValues: FormData = {
    directUploadBlobIds: [],
    fileResourceIds: legalDocument?.fileResources.map(attachment => attachment.id) ?? [],
    kind: legalDocument?.kind ?? (kindOptions.length === 1 ? kindOptions[0] : null),
    notes: legalDocument?.notes ?? null,
    status: defaultAsComplete ? LegalDocumentStatusEnum.COMPLETE : (legalDocument?.status ?? null),
    storageLocation: legalDocument?.storageLocation ?? null,
    updateDate: defaultUpdateDate ?? null
  };

  const [baseErrors, setBaseErrors] = useState<string[]>([]);
  const [fileInputBusy, setFileInputBusy] = useState<boolean>(false);
  const formState = useFormBackOrNext({ setBackOrNext });

  const schema: yup.ObjectSchema<FormData> = yup
    .object()
    .shape({
      directUploadBlobIds: yup.array().of(yup.string().defined()).defined(),
      fileResourceIds: yup.array().of(yup.string().defined()).defined(),
      kind: yup.string().oneOf(Object.values(LegalDocumentKindEnum)).nullable(),
      notes: yup.string().nullable().defined(),
      status: yup
        .string()
        .oneOf(Object.values(LegalDocumentStatusEnum))
        .required(intl.formatMessage({ id: "errors.legalDocument.status.blank" })),
      storageLocation: yup.string().nullable().defined(),
      updateDate: yup
        .string()
        .defined()
        .matches(
          /^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d)|(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/,
          intl.formatMessage({ id: "errors.legalDocument.updateDate.correctFormat" })
        )
        .nullable()
    })
    .required();

  const form = useForm<FormData>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const fileResourceIds = form.watch("fileResourceIds");

  const [createLegalDocument] = useMutation<LegalDocumentCreateMutation, LegalDocumentCreateMutationVariables>(
    LegalDocumentCreateDocument,
    {
      update: (store, { data }) => {
        const createdLegalDocument = data?.legalDocumentCreate?.legalDocument;
        if (createdLegalDocument) {
          store.modify({
            fields: {
              legalDocuments(cachedLegalDocumentRefs: readonly Reference[]) {
                const newLegalDocumentRef = store.writeFragment({
                  data: createdLegalDocument,
                  fragment: LegalDocumentFragmentDoc,
                  fragmentName: "LegalDocument"
                });
                if (newLegalDocumentRef) {
                  return [...cachedLegalDocumentRefs, newLegalDocumentRef];
                }
                return [...cachedLegalDocumentRefs];
              }
            },
            id: store.identify({ __typename: "Journey", id: journeyId })
          });
          createdLegalDocument.fileResources.forEach(fileResource => {
            store.modify({
              fields: {
                fileResources(cachedFileResourceRefs: readonly Reference[]) {
                  const newFileResourceRef = store.writeFragment({
                    data: fileResource,
                    fragment: FileResourceFragmentDoc,
                    fragmentName: "FileResource"
                  });
                  if (newFileResourceRef) {
                    return [...cachedFileResourceRefs, newFileResourceRef];
                  }
                  return [...cachedFileResourceRefs];
                }
              },
              id: store.identify({ __typename: "Journey", id: journeyId })
            });
          });
        }
      }
    }
  );

  const [updateLegalDocument] = useMutation<LegalDocumentUpdateMutation, LegalDocumentUpdateMutationVariables>(
    LegalDocumentUpdateDocument,
    {
      update: (store, { data }) => {
        if (data?.legalDocumentUpdate?.legalDocument && fileResourceIds.length > 0) {
          store.modify({
            fields: {
              fileResources(cachedFileResourceRefs: readonly Reference[], { readField }) {
                return cachedFileResourceRefs.filter(fileResourceRef =>
                  data?.legalDocumentUpdate?.legalDocument?.fileResources
                    .map(fileResource => fileResource.id)
                    .includes(readField("id", fileResourceRef)!)
                );
              }
            },
            id: store.identify({ ...legalDocument })
          });
        }
      }
    }
  );

  const generateHandleFileResourceDelete = useCallback(
    (fileResourceId: string) => () => {
      const newFileResourceIds = fileResourceIds.filter(
        previousFileResourceId => previousFileResourceId !== fileResourceId
      );
      form.setValue("fileResourceIds", newFileResourceIds, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    },
    [fileResourceIds, form]
  );

  const handleTrackerSubmit = useCallback(() => {
    formState.setFormSubmitted(true);
  }, [formState]);

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindOptions, legalDocument?.id]);

  const kind = form.watch("kind");
  const status = form.watch("status");

  const onSubmit = useCallback(async () => {
    if (kind && status) {
      setBaseErrors([]);

      if (legalDocument?.id) {
        return updateLegalDocument({
          variables: {
            ...form.getValues(),
            directUploadBlobIds: form.getValues().directUploadBlobIds,
            id: legalDocument.id,
            status
          }
        }).then(response => {
          handleMutationResponse<FormData>({
            data: response.data?.legalDocumentUpdate,
            intl,
            onError,
            onSuccess: () => {
              if (response.data?.legalDocumentUpdate?.legalDocument) {
                if (inTracker) {
                  handleTrackerSubmit();
                } else {
                  onSuccess();
                }
              }
            },
            setBaseErrors,
            setError: form.setError,
            txBase: "errors.legalDocument"
          });
        });
      }
      return createLegalDocument({
        variables: {
          ...form.getValues(),
          journeyId,
          kind,
          status
        }
      }).then(response => {
        handleMutationResponse<FormData>({
          data: response.data?.legalDocumentCreate,
          intl,
          onError,
          onSuccess: () => {
            if (response.data?.legalDocumentCreate?.legalDocument) {
              if (inTracker) {
                handleTrackerSubmit();
              } else {
                onSuccess();
              }
            }
          },
          setBaseErrors,
          setError: form.setError,
          txBase: "errors.legalDocument"
        });
      });
    }
  }, [
    createLegalDocument,
    form,
    handleTrackerSubmit,
    intl,
    inTracker,
    journeyId,
    kind,
    legalDocument?.id,
    onError,
    onSuccess,
    status,
    updateLegalDocument
  ]);

  const statusOptions = optionsForLegalDocumentStatus(intl);
  const kindSelectOptions = optionsForLegalDocumentKind(intl, kindOptions);

  const fileResourceForId = (id: string) => legalDocument?.fileResources.find(fileResource => fileResource.id === id);

  return (
    <div className={styles.form}>
      {(titleKey || messageKey) && (
        <div className={inTracker ? `${styles.info} ${styles.trackerHeading}` : styles.title}>
          {titleKey && (
            <p>
              <IonText className={styles.bold}>
                <FormattedMessage id={titleKey} />
              </IonText>
            </p>
          )}
          {messageKey && (
            <p>
              <FormattedMessage id={messageKey} />
            </p>
          )}
        </div>
      )}
      <div className={inTracker ? styles.formFields : undefined}>
        <Form data-test="legal-documents-form" onSubmit={form.handleSubmit(onSubmit)}>
          <BaseErrors errors={baseErrors} />
          <FormCondition condition={kindOptions.length > 1 && !legalDocument?.kind} fieldName="kind" form={form}>
            <Select<FormData>
              control={form.control}
              error={form.formState.errors.kind}
              label={intl.formatMessage({ id: "forms.legalDocument.kindLabel" })}
              name="kind"
              options={kindSelectOptions}
              required
            />
          </FormCondition>
          <div>
            <RadioButtonGroup<FormData>
              control={form.control}
              error={form.formState.errors.status}
              inTracker={inTracker}
              label={intl.formatMessage({ id: "forms.legalDocument.statusLabel" })}
              name="status"
              options={statusOptions}
              required
            />
          </div>

          <div className={status === LegalDocumentStatusEnum.COMPLETE ? undefined : "ion-hide"}>
            <div className={uploadOnly && status === LegalDocumentStatusEnum.COMPLETE ? "ion-hide" : ""}>
              <DateInput
                error={form.formState.errors.updateDate}
                label={intl.formatMessage({ id: "forms.legalDocument.updateDateLabel" })}
                name="updateDate"
                register={form.register("updateDate")}
              />
              <Input
                error={form.formState.errors.storageLocation}
                label={intl.formatMessage({ id: "forms.legalDocument.storageLocationLabel" })}
                register={form.register("storageLocation")}
              />
              <Input
                error={form.formState.errors.notes}
                label={intl.formatMessage({ id: "forms.legalDocument.notesLabel" })}
                register={form.register("notes")}
              />
            </div>

            <DirectUploadFileInput<FormData, string[]>
              control={form.control}
              errors={form.formState.errors.directUploadBlobIds}
              label={intl.formatMessage({ id: "forms.legalDocument.attachmentLabel" })}
              multiple
              name="directUploadBlobIds"
              onBusyChange={setFileInputBusy}
              setValue={value => {
                form.setValue("directUploadBlobIds", value);
              }}
            />
            {fileResourceIds.map(fileResourceId => (
              <IonGrid className={styles.previousAttachment} key={fileResourceId}>
                <IonRow className="ion-align-items-center">
                  <IonCol>{getFileResourceTitle(fileResourceForId(fileResourceId)!, intl)}</IonCol>
                  <IonCol size="auto">
                    <CircleButton
                      ariaLabelKey="accessibility.ariaLabels.delete"
                      fill="clear"
                      icon="trash"
                      onClick={generateHandleFileResourceDelete(fileResourceId)}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            ))}
          </div>

          {inTracker ? (
            <BackAndNextFormButtons
              backDisabled={backDisabled}
              hookForm={form}
              saveDisabled={!form.formState.isValid || form.formState.isSubmitting || fileInputBusy}
              saveKey={kind === LegalDocumentKindEnum.WILL ? "dictionary.complete" : undefined}
              setBackOrNext={formState.setFormBackOrNext}
            />
          ) : (
            <FormButtons hookForm={form} onCancel={onCancel} saveDisabled={fileInputBusy} />
          )}
        </Form>
      </div>
    </div>
  );
};

export default LegalDocumentForm;
