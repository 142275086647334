import languageData from "@utils/languageData.json";

type LanguageList = Record<string, string>;

const typedLanguageData = languageData as unknown as LanguageList;

type Option = {
  label: string;
  value: string;
};

export const languageSelectOptions: (isMulti?: boolean) => Option[] = (isMulti?: boolean) =>
  Object.keys(typedLanguageData).map(key => ({
    color: isMulti ? "#6ab7d0" : undefined,
    label: typedLanguageData[key],
    value: key
  }));

export const languageNameForCode = (languageCode: string | null | undefined): string => {
  if (languageCode) {
    return typedLanguageData[languageCode];
  }
  return "";
};
