import { PropsWithChildren } from "react";

import BodyCopy from "@components/v1/typography/BodyCopy";
import styles from "@components/v1/typography/Typography.module.css";

const FormError = ({ children }: PropsWithChildren) => (
  <div className={styles.formError}>
    <BodyCopy bold color="danger" size="small">
      {children}
    </BodyCopy>
  </div>
);

export default FormError;
