import { SetStateAction, useEffect, useState } from "react";

import { BackOrNextEnum } from "@typing/Enums";

type Props = {
  setBackOrNext: ((value: SetStateAction<BackOrNextEnum | null>) => void) | undefined;
};

const useFormBackOrNext = ({ setBackOrNext }: Props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formBackOrNext, setFormBackOrNext] = useState<BackOrNextEnum | null>(null);

  useEffect(() => {
    if (formSubmitted && formBackOrNext) {
      setFormSubmitted(false);
      if (setBackOrNext) {
        setBackOrNext(formBackOrNext);
        setFormBackOrNext(null);
      }
    }
  }, [formSubmitted, formBackOrNext, setBackOrNext]);

  return { setFormBackOrNext, setFormSubmitted };
};

export default useFormBackOrNext;
