export const credentialsStorageKey = "graycecredentials";
export const automaticallyRegisterForArticleIdKey = "automaticallyRegisterForArticleId";
export const automaticallyRegisterForArticleStartTimeKey = "automaticallyRegisterForArticleStartTime";

export const oidcNonceKey = "oidcNonce";
export const oidcStateKey = "oidcState";

export const registerForWebinarAutomaticallyIdParam = "registerForWebinarAutomaticallyId";
export const registerForWebinarAutomaticallyStartTimeParam = "registerForWebinarAutomaticallyStartTime";

export const drugSelectOther = "other";
export const employerSelectSelfPaid = "self_paid";

export const fifteenMinutesInMs = 15 * 60 * 1000;
export const fiveDaysInMs = 5 * 24 * 60 * 60 * 1000;
export const fiveMinutesInMs = 5 * 60 * 1000;
export const oneDayInMs = 24 * 60 * 60 * 1000;
export const oneHourInMs = 60 * 60 * 1000;
export const oneMinuteInMs = 60 * 1000;
export const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
export const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
export const thirtySecondsInMs = 30 * 1000;
export const threeDaysInMS = 3 * 24 * 60 * 60 * 1000;
export const twoMinutesInMs = 2 * 60 * 1000;

export const liveUpdateAvailableStorageKey = "liveUpdateAvailable";
export const blockLiveUpdatesStorageKey = "blockLiveUpdates";
export const storageFalseValue = "no";
export const storageTrueValue = "yes";
