import { IonText } from "@ionic/react";
import { ComponentProps } from "react";

import styles from "@components/v1/typography/Typography.module.css";
import { Colors } from "@utils/colorUtils";

type Props = Omit<ComponentProps<typeof IonText>, "color"> & {
  bold?: boolean;
  color?: Colors;
  italic?: boolean;
  lineThrough?: boolean;
  size?: "small" | "medium" | "large";
  underline?: boolean;
};

const BodyCopy = ({
  bold,
  className,
  color = "gray",
  italic = false,
  lineThrough = false,
  size = "medium",
  underline = false,
  ...ionTextProps
}: Props) => {
  const wrapperStyles = [styles.bodyCopy, styles[size]];

  if (bold) {
    wrapperStyles.push(styles.bold);
  }

  if (italic) {
    wrapperStyles.push(styles.italic);
  }

  if (lineThrough) {
    wrapperStyles.push(styles.linethrough);
  }

  if (underline) {
    wrapperStyles.push(styles.underline);
  }

  if (className) {
    wrapperStyles.push(className);
  }

  return <IonText className={wrapperStyles.join(" ")} color={color} {...ionTextProps} />;
};

export default BodyCopy;
