import { useCallback, useState } from "react";

export type Opener = {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  toggle: () => void;
};

const useOpener = (defaultOpen = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(previousIsOpen => !previousIsOpen);
  }, []);

  const opener: Opener = {
    close,
    isOpen,
    open,
    toggle
  };

  return opener;
};

export default useOpener;
