import { IonItem } from "@ionic/react";

import CardBase from "@components/v1/cards/CardBase";
import itemStyles from "@components/v1/trackers/TrackedItemLine.module.css";
import BodyCopy from "@components/v1/typography/BodyCopy";
import IconBase from "@components/v2/icons/IconBase";
import useDownloadFileResource from "@hooks/useDownloadFileResource";
import { FileResourceFragment } from "@typing/Generated";

type Props = {
  fileResource: FileResourceFragment;
};

const FileResourceCard = ({ fileResource }: Props) => {
  const handleDownload = useDownloadFileResource(fileResource);

  return (
    <CardBase
      body={
        <IonItem className={itemStyles.item} color="gray-100" lines="none">
          <IconBase
            className="margin-4-top margin-4-bottom margin-10-right"
            color="gray-200"
            icon={fileResource.attachment?.scanning ? "spinner" : "paperclip"}
            size="medium"
            slot="start"
          />
          <div className="text-ellipsis">
            <BodyCopy className="no-wrap" color="dark" size="small">
              {fileResource.title}
            </BodyCopy>
          </div>
        </IonItem>
      }
      border={false}
      className="margin-10-top"
      color="gray-100"
      contentClassName="ion-no-padding"
      key={fileResource.id}
      onClick={fileResource.attachment?.scanning ? undefined : handleDownload}
    />
  );
};

export default FileResourceCard;
