import { useMutation } from "@apollo/client";
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonProgressBar, IonRow } from "@ionic/react";
import { ChangeEvent, InputHTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Control, Controller, FieldError, FieldPath, FieldValues, Merge } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import styles from "@components/v1/fields/DirectUploadFileInput.module.css";
import Error from "@components/v1/fields/Error";
import RequiredFieldIndicator from "@components/v1/fields/RequiredFieldIndicator";
import InputLabel from "@components/v1/typography/InputLabel";
import Button from "@components/v2/buttons/Button";
import CircleButton from "@components/v2/buttons/CircleButton";
import useMountedTracking from "@hooks/useMountedTracking";
import {
  DirectUploadCreateDocument,
  DirectUploadCreateMutation,
  DirectUploadCreateMutationVariables
} from "@typing/Generated";
import { computeChecksumMd5, directUpload } from "@utils/fileUtils";

type DirectUpload = NonNullable<NonNullable<DirectUploadCreateMutation["directUploadCreate"]>["directUpload"]>;

type DirectUploadAndFile = {
  directUpload: DirectUpload;
  file: File;
};

type UploadProps = {
  onDelete: (uploadAndFile: DirectUploadAndFile) => void;
  onUploadComplete: (uploadAndFile: DirectUploadAndFile) => void;
  uploadAndFile: DirectUploadAndFile;
};

const Upload = ({ onDelete, onUploadComplete, uploadAndFile }: UploadProps) => {
  const isMounted = useMountedTracking();

  const [progress, setProgress] = useState<number>(0);
  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    directUpload({
      file: uploadAndFile.file,
      jsonEncodedHeaders: uploadAndFile.directUpload.headers,
      onProgress: percentComplete => {
        setProgress(percentComplete);
      },
      url: uploadAndFile.directUpload.url
    })
      .then(() => {
        onUploadComplete(uploadAndFile);
      })
      .finally(() => {
        if (isMounted.current) setDone(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(uploadAndFile);
  }, [onDelete, uploadAndFile]);

  return (
    <IonCard className={styles.uploadCard + " " + (done ? styles.completedUploadCard : "")}>
      <CircleButton ariaLabelKey="accessibility.ariaLabels.delete" icon="xmark" onClick={handleDelete} size="small" />
      <IonCardHeader>{uploadAndFile.file.name}</IonCardHeader>
      <IonCardContent>
        <IonProgressBar value={progress ? progress / 100.0 : 0.0} />
      </IonCardContent>
    </IonCard>
  );
};

type Props<FormData extends FieldValues, ValueType> = InputHTMLAttributes<HTMLInputElement> & {
  clearFileTypeError?: () => void;
  control: Control<FormData>;
  errors: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  label?: string | ReactNode;
  name: FieldPath<FormData>;
  onBusyChange: (busy: boolean) => void;
  onFileSelected?: (filenames: string[]) => void;
  required?: boolean;
  setFileTypeError?: () => void;
  setValue: (value: ValueType) => void;
  smallWrapper?: boolean;
  validTypes?: string[];
};

const DirectUploadFileInput = <FormData extends FieldValues, ValueType>({
  accept,
  clearFileTypeError,
  control,
  errors,
  label,
  multiple,
  name,
  onBusyChange,
  onFileSelected,
  required,
  setFileTypeError,
  setValue,
  smallWrapper = false,
  validTypes,
  ...inputProps
}: Props<FormData, ValueType>) => {
  const intl = useIntl();
  const [directUploadAndFiles, setDirectUploadAndFiles] = useState<DirectUploadAndFile[]>([]);
  const busyFiles = useRef<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMounted = useMountedTracking();
  const [createDirectUpload] = useMutation<DirectUploadCreateMutation, DirectUploadCreateMutationVariables>(
    DirectUploadCreateDocument
  );

  const handleUploadComplete = useCallback(
    (uploadAndFile: DirectUploadAndFile) => {
      const index = busyFiles.current.indexOf(uploadAndFile.file);
      if (index !== -1) {
        const newBusyFiles = [...busyFiles.current];
        newBusyFiles.splice(index, 1);
        busyFiles.current = newBusyFiles;
        if (newBusyFiles.length === 0) {
          onBusyChange(false);
        }
      }
    },
    [busyFiles, onBusyChange]
  );

  const handleUploadAndFileDeleted = useCallback(
    (uploadAndFile: DirectUploadAndFile) => {
      handleUploadComplete(uploadAndFile);
      const index = directUploadAndFiles.indexOf(uploadAndFile);
      const newDirectUploadAndFiles = [...directUploadAndFiles];
      newDirectUploadAndFiles.splice(index, 1);
      setDirectUploadAndFiles(newDirectUploadAndFiles);
      // the following code uses the "best method" here for removing the file from the input itself:
      // https://stackoverflow.com/a/64019766/7599680
      const files = inputRef.current?.files;
      if (files && Array.from(files).includes(uploadAndFile.file)) {
        const dt = new DataTransfer();
        for (const file of files) {
          if (file !== uploadAndFile.file) {
            dt.items.add(file);
          }
        }
        inputRef.current.files = dt.files;
      }
      if (multiple) {
        setValue(newDirectUploadAndFiles.map(item => item.directUpload.signedBlobId) as unknown as ValueType);
      } else {
        setValue((newDirectUploadAndFiles[0]?.directUpload?.signedBlobId ?? null) as unknown as ValueType);
      }
    },
    [directUploadAndFiles, handleUploadComplete, multiple, setValue]
  );

  const handleStartUpload = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const generateHandleChange = useCallback(
    (onChange: (event: string | string[]) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      const files = e?.target?.files;
      if (files) {
        if (onFileSelected) {
          const filenames: string[] = [];
          for (let index = 0; index < files.length; ++index) {
            const file = e?.target?.files?.[index];
            if (file) filenames.push(file.name);
          }

          onFileSelected(filenames);
        }

        if (!multiple) {
          setDirectUploadAndFiles([]);
        }
        const createDirectUploadPromises: Promise<DirectUploadAndFile>[] = [];
        onBusyChange(true);
        const newBusyFiles: File[] = [...busyFiles.current];

        for (let index = 0; index < files.length; ++index) {
          const file = e?.target?.files?.[index];
          if (file) {
            if (validTypes && setFileTypeError && !validTypes.includes(file.type)) {
              setFileTypeError();
              onBusyChange(false);
            } else {
              if (clearFileTypeError) {
                clearFileTypeError();
              }
              newBusyFiles.push(file);
              createDirectUploadPromises.push(
                new Promise((resolve, reject) => {
                  computeChecksumMd5(file).then(checksum => {
                    createDirectUpload({
                      variables: {
                        byteSize: file.size,
                        checksum,
                        contentType: file.type,
                        filename: file.name
                      }
                    }).then(response => {
                      const directUpload = response.data?.directUploadCreate?.directUpload;
                      if (directUpload) {
                        resolve({ directUpload, file });
                      } else {
                        reject("NO BLOB ID");
                      }
                    });
                  });
                })
              );
              busyFiles.current = newBusyFiles;

              Promise.all<DirectUploadAndFile>(createDirectUploadPromises).then(result => {
                const existing = multiple ? directUploadAndFiles : [];
                const newDirectUploadAndFiles = [...existing, ...result];
                if (isMounted.current) {
                  if (multiple) {
                    onChange(newDirectUploadAndFiles.map(upload => upload.directUpload.signedBlobId));
                  } else {
                    onChange(newDirectUploadAndFiles[0].directUpload.signedBlobId);
                  }
                  setDirectUploadAndFiles(newDirectUploadAndFiles);
                }
              });
            }
          }
        }
      }
    },
    [
      clearFileTypeError,
      createDirectUpload,
      directUploadAndFiles,
      isMounted,
      multiple,
      onBusyChange,
      onFileSelected,
      setFileTypeError,
      validTypes
    ]
  );

  return (
    <>
      <div className={smallWrapper ? undefined : styles.wrapperItem}>
        <InputLabel>
          {label}
          {required && <RequiredFieldIndicator />}
        </InputLabel>

        <Controller
          control={control}
          name={name}
          render={({ field: { name, onBlur, onChange, value } }) => {
            const typedValue = value as string[] | null;
            if (multiple && typedValue?.length !== directUploadAndFiles.length && typedValue?.length === 0) {
              setDirectUploadAndFiles([]);
              busyFiles.current = [];
            }
            return (
              <>
                <input
                  accept={accept ? accept : "*"}
                  aria-label={intl.formatMessage({
                    id: "accessibility.ariaLabels.attachFiles"
                  })}
                  className={styles.input}
                  multiple={multiple}
                  name={name}
                  onBlur={onBlur}
                  onChange={generateHandleChange(onChange)}
                  ref={inputRef}
                  tabIndex={-1}
                  type="file"
                  {...inputProps}
                />
                <div className={smallWrapper ? styles.fakeInputWrapper + " ion-no-margin" : styles.fakeInputWrapper}>
                  <Button fill="outline" onClick={handleStartUpload}>
                    <FormattedMessage
                      id={
                        multiple && directUploadAndFiles.length > 0
                          ? "forms.directUploadFileInput.attachMoreFiles"
                          : multiple
                            ? "forms.directUploadFileInput.attachFiles"
                            : "forms.directUploadFileInput.selectAFile"
                      }
                    />
                  </Button>
                </div>
              </>
            );
          }}
        />
        <div className={styles.attachmentsWrapper}>
          <IonGrid>
            <IonRow>
              {directUploadAndFiles?.map(directUploadAndFile => (
                <IonCol
                  className={styles.inputGridCol}
                  key={directUploadAndFile.directUpload.blobId}
                  size={smallWrapper ? "12" : "4"}
                >
                  <Upload
                    onDelete={handleUploadAndFileDeleted}
                    onUploadComplete={handleUploadComplete}
                    uploadAndFile={directUploadAndFile}
                  />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
      </div>
      {errors
        ?.filter?.(e => e)
        .map(error => (
          <div className={styles.errorWrapper} key={error?.message}>
            <Error error={error} />
          </div>
        ))}
    </>
  );
};

export default DirectUploadFileInput;
