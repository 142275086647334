import { Browser } from "@capacitor/browser";
import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

type Args = Pick<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "onClick"> & {
  href: string;
};

type Return = Pick<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  "href" | "onClick" | "onKeyDown" | "rel" | "tabIndex" | "target"
>;

const useExternalLinkClick = ({ href, onClick }: Args): Return => {
  const anchorProps: Return = {};

  if (href === null || href === undefined) return anchorProps;

  if (href.startsWith("mailto") || href.startsWith("tel")) {
    anchorProps.href = href;
    anchorProps.rel = "noopener noreferrer";
    anchorProps.target = "_blank";
  } else {
    let finalUrlString = "";

    // Somehow we are getting non-URL stuff in here, and that results in an exception
    // because the URL thing can't process those. This lets it fall back to just opening
    // the thing if it can't be parsed, which could be problematic but seems better than
    // the alternative of losing things that might somehow be valid and yet aren't usable
    // by URL. It's also what the behavior used to be.
    try {
      const url = new URL(href);

      if (href.includes(import.meta.env.VITE_APP_HOST)) {
        url.searchParams.set("skipCookieBanner", "true");
      }
      finalUrlString = url.toString();
    } catch {
      finalUrlString = href;
    }

    anchorProps.tabIndex = 0;
    anchorProps.onKeyDown = e => {
      if (e.key === "Enter") {
        Browser.open({ url: finalUrlString });
      }
    };
    anchorProps.onClick = e => {
      if (onClick) onClick(e);
      Browser.open({ url: finalUrlString });
    };
  }

  return anchorProps;
};

export default useExternalLinkClick;
