import { IonText } from "@ionic/react";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import styles from "@components/v1/typography/Typography.module.css";
import { Colors } from "@utils/colorUtils";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  bold?: boolean;
  color?: Colors;
};

const Heading2 = ({ bold = true, children, className, color = "primary", ...headingProps }: Props) => {
  const wrapperStyles = [styles.heading2];

  if (bold) {
    wrapperStyles.push(styles.bold);
  }

  if (className) {
    wrapperStyles.push(className);
  }

  return (
    <h2 className={wrapperStyles.join(" ")} {...headingProps}>
      <IonText color={color}>{children}</IonText>
    </h2>
  );
};

export default Heading2;
