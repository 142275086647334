import { IonDatetime, IonInput } from "@ionic/react";
import { addYears, max as maxDate, min as minDate, subYears } from "date-fns";
import { ComponentProps, useMemo } from "react";
import { FieldError, FieldValues, Merge, Path, UseFormRegisterReturn } from "react-hook-form";

import DateTime from "@components/v1/fields/DateTime";
import Input from "@components/v1/fields/Input";
import { isMobileWeb, isNativeApp } from "@utils/platformUtils";
import { dateToMDYString } from "@utils/timeUtils";

type Props<FormData extends FieldValues> = Omit<ComponentProps<typeof IonInput>, "value"> & {
  actions?: ComponentProps<typeof Input>["actions"];
  autofocus?: boolean;
  disabled?: boolean;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  futureOnly?: boolean;
  label?: string;
  max?: ComponentProps<typeof IonDatetime>["max"];
  min?: ComponentProps<typeof IonDatetime>["min"];
  pastOnly?: boolean;
  register: UseFormRegisterReturn<Path<FormData>>;
  required?: boolean;
  simpleInput?: boolean;
  skipSpacing?: boolean;
  spellcheck?: boolean;
  value?: string | null;
};

const DateInput = <FormData extends FieldValues>({
  autofocus = false,
  disabled = false,
  error,
  futureOnly = false,
  label,
  max,
  min,
  pastOnly = false,
  register,
  required,
  simpleInput = false,
  skipSpacing = false,
  spellcheck = false,
  value,
  ...inputProps
}: Props<FormData>) => {
  const minMax = useMemo(() => {
    let useMax: Date = max ? new Date(max) : addYears(new Date(), 125);
    let useMin: Date = min ? new Date(min) : subYears(new Date(), 125);
    const now = new Date();

    if (pastOnly) {
      useMax = minDate([useMax, now]);
    }

    if (futureOnly) {
      useMin = maxDate([useMin, now]);
    }

    return { max: dateToMDYString(useMax), min: dateToMDYString(useMin) };
  }, [futureOnly, max, min, pastOnly]);

  if (isNativeApp() || isMobileWeb()) {
    return (
      <DateTime
        dateOnly
        disabled={disabled}
        error={error}
        label={label}
        max={minMax.max}
        min={minMax.min}
        name={register.name}
        preferWheel
        presentation="date"
        register={register}
        required={required}
        spellCheck={spellcheck}
        value={value}
      />
    );
  }

  return (
    <Input<FormData>
      autofocus={autofocus}
      disabled={disabled}
      error={error}
      label={label}
      max={minMax.max}
      min={minMax.min}
      noSpacing={skipSpacing}
      register={register}
      required={required}
      simpleInput={simpleInput}
      spellcheck={spellcheck}
      type="date"
      {...inputProps}
    />
  );
};

export default DateInput;
