import { IonIcon } from "@ionic/react";
import { ComponentProps } from "react";

import styles from "@components/v2/icons/IconBase.module.css";
import { IconList, IconName } from "@utils/iconUtils";

type Props = Omit<ComponentProps<typeof IonIcon>, "icon" | "src"> & {
  icon: IconName;
};

const IconBase = ({ className, icon, ...props }: Props) => {
  const classNames = [];
  if (className) classNames.push(className);
  if (icon === "spinner") classNames.push(styles.spin);

  return <IonIcon {...props} className={classNames.join(" ")} src={IconList[icon]} />;
};

export default IconBase;
