import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

import linkStyles from "@components/v1/typography/InternalLink.module.css";
import IconBase from "@components/v2/icons/IconBase";
import useExternalLinkClick from "@hooks/useExternalLinkClick";
import useMutationWithValidation from "@hooks/useMutationWithValidation";
import { LinkEventCreateDocument, LinkEventCreateMutation, LinkEventCreateMutationVariables } from "@typing/Generated";
import { Colors } from "@utils/colorUtils";

type AnchorProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
type Props = Omit<AnchorProps, "rel" | "tabIndex" | "target"> &
  Required<Pick<AnchorProps, "href">> & {
    arrow?: boolean;
    color?: Extract<Colors, "white">;
    expand?: boolean;
    linkEventCreateVariables: Omit<LinkEventCreateMutationVariables, "link">;
    stylized?: boolean;
  };

const ExternalLink = ({
  arrow = false,
  children,
  className,
  color,
  expand = false,
  href,
  linkEventCreateVariables = {},
  onClick,
  stylized = true,
  ...passedInAnchorProps
}: Props) => {
  const classNames = ["component-ExternalLink", linkStyles.reset, arrow && linkStyles.withArrow];
  if (expand && !arrow) classNames.push(linkStyles.expand);
  if (stylized && !arrow) classNames.push(linkStyles.stylized);
  if (className) classNames.push(className);
  if (color) classNames.push(linkStyles[color]);

  const [linkEventCreate] = useMutationWithValidation<LinkEventCreateMutation, LinkEventCreateMutationVariables>(
    LinkEventCreateDocument,
    "linkEvent"
  );

  const anchorProps = useExternalLinkClick({
    href,
    onClick: e => {
      if (onClick) onClick(e);
      linkEventCreate({ ...linkEventCreateVariables, link: href });
    }
  });

  return (
    // eslint-disable-next-line react/forbid-elements
    <a {...passedInAnchorProps} {...anchorProps} className={classNames.join(" ")}>
      <span className={linkStyles.text}>{children}</span>
      {arrow && (
        <span className={linkStyles.arrow}>
          <IconBase icon="chevronRight" />
        </span>
      )}
    </a>
  );
};

export default ExternalLink;
