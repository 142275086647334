import { isKeyOfObject } from "@utils/objectUtils";

type Query = Record<string, string>;

export const fullInternalUrl = (path: string) => {
  if (!path.startsWith("/")) path = `/${path}`;
  const appDomain = import.meta.env.VITE_APP_HOST;
  return `${appDomain}${path}`;
};

export const linkedInSearchUrl = (query: Query) => {
  const url = new URL("https://www.linkedin.com/search/results/all/");
  url.search = new URLSearchParams(query).toString();
  return url.toString();
};

export const extractResourcesFromPath = (path: string) => {
  const pathParts = path.split("/").filter(part => part.length > 0);
  const resources: Record<string, string> = {};
  const resourceKeywords = {
    articles: "article_id",
    journeys: "journey_id"
  };

  for (let i = 0; i < pathParts.length; i++) {
    const part = pathParts[i];
    if (isKeyOfObject(part, resourceKeywords)) {
      const resource = resourceKeywords[part];
      const id = pathParts[i + 1];
      if (id) {
        resources[resource] = id;
      }
    }
  }

  return resources;
};

export const privacyPolicyUrl = "https://withgrayce.com/privacy-policy/";
