import { IonText } from "@ionic/react";
import classNames from "classnames";
import { ComponentProps, ReactNode } from "react";

import ConditionalTooltip from "@components/v1/ConditionalTooltip";
import styles from "@components/v2/icons/Icon.module.css";
import IconBase from "@components/v2/icons/IconBase";
import { Colors } from "@utils/colorUtils";

type Props = Omit<
  ComponentProps<typeof IconBase>,
  "aria-describedby" | "aria-label" | "data-test" | "name" | "onBlur" | "onFocus" | "role"
> & {
  color?: Colors;
  hoverContent?: ReactNode;
  size?: "tiny" | "small" | "medium" | "large" | "xlarge" | "massive" | "titanic";
  spacingEnd?: boolean;
  spacingStart?: boolean;
  wrapperClassName?: string;
};

const Icon = ({
  className,
  color = "dark",
  hoverContent,
  size = "medium",
  spacingEnd,
  spacingStart,
  wrapperClassName,
  ...iconProps
}: Props) => (
  <ConditionalTooltip condition={!!hoverContent} content={hoverContent}>
    {tooltipTriggerProps => (
      <IonText className={classNames(styles.iconWrapper, wrapperClassName)} color={color}>
        <IconBase
          className={classNames(
            styles.icon,
            styles[size],
            className,
            spacingEnd && styles.spacingEnd,
            spacingStart && styles.spacingStart
          )}
          color={color}
          data-test="icon"
          {...iconProps}
          {...tooltipTriggerProps}
        />
      </IonText>
    )}
  </ConditionalTooltip>
);

export default Icon;
