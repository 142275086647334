import { ComponentProps } from "react";

import CardBase from "@components/v1/cards/CardBase";
import BodyCopy from "@components/v1/typography/BodyCopy";
import Heading2 from "@components/v1/typography/Heading2";
import Icon from "@components/v2/icons/Icon";

type Props = Pick<ComponentProps<typeof CardBase>, "onClick"> & {
  subtitle: string;
  title: string;
};

const TrackedItem = ({ subtitle, title, ...cardBaseProps }: Props) => (
  <CardBase
    body={
      <div className="flex items-center">
        <div className="flex-grow">
          <Heading2 bold color="primary">
            {title}
          </Heading2>
          <BodyCopy color="primary">{subtitle}</BodyCopy>
        </div>
        <Icon color="primary" icon="chevronRight" />
      </div>
    }
    border={false}
    color="primary-100"
    contentClassName="padding-16-start padding-16-end padding-8-top padding-8-bottom"
    {...cardBaseProps}
  />
);

export default TrackedItem;
