import { DOMAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";

import styles from "@components/v1/typography/InternalLink.module.css";
import IconBase from "@components/v2/icons/IconBase";
import useMutationWithValidation from "@hooks/useMutationWithValidation";
import { LinkEventCreateDocument, LinkEventCreateMutation, LinkEventCreateMutationVariables } from "@typing/Generated";
import { Colors } from "@utils/colorUtils";
import { fullInternalUrl } from "@utils/linkUtils";

// react-router Link is automatically in the tab index, so don't let clients set it
type Props = Omit<LinkProps, "href"> & {
  arrow?: boolean;
  color?: Extract<Colors, "white">;
  expand?: boolean;
  linkEventCreateVariables: Omit<LinkEventCreateMutationVariables, "link">;
  stylized?: boolean;
};

const InternalLink = ({
  arrow = false,
  children,
  className,
  color,
  expand = false,
  linkEventCreateVariables = {},
  onClick,
  stylized = true,
  ...otherAnchorProps
}: Props) => {
  const classNames = ["component-InternalLink", styles.reset, arrow && styles.withArrow];
  if (expand && !arrow) classNames.push(styles.expand);
  if (stylized && !arrow) classNames.push(styles.stylized);
  if (className) classNames.push(className);
  if (color) classNames.push(styles[color]);

  const [linkEventCreate] = useMutationWithValidation<LinkEventCreateMutation, LinkEventCreateMutationVariables>(
    LinkEventCreateDocument,
    "linkEvent"
  );

  const handleClick: DOMAttributes<HTMLAnchorElement>["onClick"] = e => {
    if (onClick) onClick(e);
    if (otherAnchorProps.to) {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      linkEventCreate({ ...linkEventCreateVariables, link: fullInternalUrl(otherAnchorProps.to.toString()) });
    }
  };

  return (
    // eslint-disable-next-line react/forbid-elements
    <Link className={classNames.join(" ")} onClick={handleClick} {...otherAnchorProps}>
      <span className={styles.text}>{children}</span>
      {arrow && (
        <span className={styles.arrow}>
          <IconBase icon="chevronRight" />
        </span>
      )}
    </Link>
  );
};

export default InternalLink;
