// type guard used to filter null and undefined values out of an array and ensure the type of the
// resulting array doesn't include null or undefined. to be used in an array filter like so:
//   const filteredArray = array.filter(isNotNullOrUndefined);
export const isNotNullOrUndefined = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;

// This function is a bit confusing (as is anything that uses reduce) but its a more efficient way
// of performing a mapped transform on an array that might result in null/undefined values and
// compacting the result to exclude the null/undefined values.
export const compactMap = <T, U>(array: T[], transform: (value: T) => U | null | undefined): U[] =>
  array.reduce((acc: U[], currentValue) => {
    const transformedValue = transform(currentValue);
    if (isNotNullOrUndefined(transformedValue)) {
      acc.push(transformedValue);
    }
    return acc;
  }, []);
