import { SubscriptionHookOptions, useSubscription } from "@apollo/client";

import {
  JourneySignedDocumentsDocument,
  JourneySignedDocumentsSubscription,
  JourneySignedDocumentsSubscriptionVariables
} from "@typing/Generated";

// JourneySignedDocuments_journeySignedDocuments_signedDocument as SignedDocument
type SignedDocument = NonNullable<
  NonNullable<JourneySignedDocumentsSubscription["journeySignedDocuments"]>["signedDocument"]
>;

type UpdateFunc = (signedDocument: SignedDocument) => void;

const useSubscribeToJourneySignedDocuments = (
  journeyId: string,
  onUpdate?: UpdateFunc,
  options?: SubscriptionHookOptions<JourneySignedDocumentsSubscription, JourneySignedDocumentsSubscriptionVariables>
) => {
  useSubscription<JourneySignedDocumentsSubscription, JourneySignedDocumentsSubscriptionVariables>(
    JourneySignedDocumentsDocument,
    {
      ...options,
      onData: data => {
        if (data.data.data?.journeySignedDocuments?.signedDocument && onUpdate) {
          onUpdate(data.data.data.journeySignedDocuments.signedDocument);
        }
      },
      variables: {
        journeyId
      }
    }
  );
};

export default useSubscribeToJourneySignedDocuments;
