import { ReactNode, useMemo, useState } from "react";
import { Controller, Control as ControlType, FieldError, FieldPath, FieldValues, Merge } from "react-hook-form";
import { useIntl } from "react-intl";
import NormalSelect, {
  components,
  ControlProps,
  FormatOptionLabelMeta,
  GroupBase,
  InputProps,
  OptionProps
} from "react-select";

import Error from "@components/v1/fields/Error";
import RequiredFieldIndicator from "@components/v1/fields/RequiredFieldIndicator";
import styles from "@components/v1/fields/Select.module.css";
import InputLabel from "@components/v1/typography/InputLabel";
import Icon from "@components/v2/icons/Icon";
import { Colors } from "@utils/colorUtils";
import { floatingSelectStyles, selectStyles } from "@utils/formUtils";
import { IconName } from "@utils/iconUtils";

export type Option = {
  color?: string;
  description?: string | undefined;
  iconBackgroundColor?: Colors;
  iconColor?: Colors;
  iconName?: IconName | null;
  label: string;
  nested?: boolean | undefined;
  selectedDescription?: string | undefined;
  value: string | number;
};

type Group = {
  label: string;
  options: Option[];
};

type SpacingSize = "NONE" | "NORMAL";

type Props<FormData extends FieldValues> = {
  clearable?: boolean;
  control: ControlType<FormData>;
  disabled?: boolean;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  isMulti?: boolean;
  label?: string | ReactNode;
  labelPlacement?: "stacked";
  mutuallyExclusiveValues?: string[];
  name: FieldPath<FormData>;
  options: Option[] | Group[];
  placeholder?: string;
  required?: boolean;
  simpleInput?: boolean;
  spacingSize?: SpacingSize;
  tabIndex?: number;
};

const Input = ({ ...props }) => (
  <components.Input {...(props as InputProps<unknown, boolean, GroupBase<unknown>>)} data-lpignore="true" />
);

const IconOption = (props: OptionProps<Option, boolean, GroupBase<Option>>) => (
  <>
    {props.data.iconName ? (
      <components.Option {...props}>
        <div className={styles.iconOptionRow}>
          <Icon color={props.data.iconColor} icon={props.data.iconName} />
          <div>{props.data.label}</div>
        </div>
      </components.Option>
    ) : (
      <components.Option {...props} />
    )}
  </>
);

const Control = (props: ControlProps<Option, boolean, GroupBase<Option>>) => {
  const values = props.getValue();

  if (values.length === 1 && values[0].iconName) {
    const value = values[0];
    return (
      <components.Control {...props}>
        <Icon color={value.iconColor} icon={value.iconName!} spacingStart />
        {props.children}
      </components.Control>
    );
  }

  return <components.Control {...props}>{props.children}</components.Control>;
};

const Select = <FormData extends FieldValues>({
  clearable,
  control,
  disabled = false,
  error,
  isMulti = false,
  label,
  labelPlacement,
  mutuallyExclusiveValues,
  name,
  options,
  placeholder,
  required,
  simpleInput = true,
  spacingSize = "NORMAL",
  tabIndex
}: Props<FormData>) => {
  const intl = useIntl();

  const isClearable = useMemo(() => clearable ?? isMulti, [clearable, isMulti]);

  const [onMutuallyExclusiveOption, setOnMutuallyExclusiveOption] = useState<boolean>(false);

  const wrapperClass = useMemo(() => {
    if (spacingSize === "NONE") {
      return styles.noMargin;
    }
    return "";
  }, [spacingSize]);

  const formatOptionLabel = (option: Option, metadata: FormatOptionLabelMeta<Option>) => (
    <div className={metadata.context === "menu" && option.nested ? styles.nested : undefined}>
      <div>
        {option.selectedDescription && metadata.context === "value"
          ? option.selectedDescription + option.label
          : option.label}
      </div>
      {/* Only show the description inside of the menu, if the value is selected show the description as white */}
      {option.description && metadata.context === "menu" && (
        <div
          className={`${styles.smallText} ${
            metadata.selectValue.length && metadata.selectValue[0].value === option.value ? styles.whiteText : ""
          }`}
        >
          {option.description}
        </div>
      )}
    </div>
  );

  const isGroup = (options: Option[] | Group[]): options is Group[] =>
    !!(options.length && (options as Group[])[0].options !== undefined);

  const getValue = (value: unknown) => {
    if (isGroup(options)) {
      return (
        options
          .map(option => option.options)
          .flat()
          .find(option => option.value === value) ??
        options
          .map(option => option.options)
          .flat()
          .find(option => option.value === "") ?? { label: "", value: "" }
      );
    }
    if (isMulti) {
      if (value) {
        return options.filter(option => (value as (string | number)[]).includes(option.value));
      }
      return [];
    }
    return (
      options.find(option => option.value === value) ??
      options.find(option => option.value === "") ?? { label: "", value: "" }
    );
  };

  const arrayErrors = useMemo(() => {
    if (error === undefined) {
      return undefined;
    }

    if (!Array.isArray(error)) {
      return [error];
    }
    return error;
  }, [error]);

  return (
    <>
      <div className={`${styles.wrapperItem} ${wrapperClass} `}>
        {label && (
          <div>
            <InputLabel labelPlacement={labelPlacement}>
              {label}
              {required && <RequiredFieldIndicator />}
            </InputLabel>
          </div>
        )}
        <Controller
          control={control}
          name={name}
          render={({ field: { name, onChange, value } }) => (
            <div className={spacingSize === "NONE" ? "" : styles.margin}>
              <NormalSelect
                aria-describedby={arrayErrors && arrayErrors.length > 0 ? `${name}-errors` : ""}
                aria-invalid={arrayErrors?.length ? "true" : "false"}
                aria-label={name}
                aria-required={required}
                classNamePrefix="react-select"
                closeMenuOnSelect={!isMulti}
                components={{ Control, Input, Option: IconOption }}
                formatOptionLabel={formatOptionLabel}
                hideSelectedOptions={false}
                id={`${name}-input`}
                isClearable={isClearable}
                isDisabled={disabled}
                isMulti={isMulti ? isMulti : undefined}
                isSearchable
                menuPortalTarget={document.body}
                name={name}
                onChange={option => {
                  const properlyTypedOption = option as unknown as Option;
                  const properlyTypedMultiOption = option as unknown as Option[];
                  if (isMulti) {
                    if (mutuallyExclusiveValues) {
                      if (onMutuallyExclusiveOption) {
                        const nonMutuallyExclusiveSelections = properlyTypedMultiOption.filter(
                          o => !mutuallyExclusiveValues.includes(o.value.toString())
                        );

                        if (nonMutuallyExclusiveSelections.length > 0) {
                          setOnMutuallyExclusiveOption(false);
                          onChange(nonMutuallyExclusiveSelections.map(o => o.value));
                        } else {
                          const mutuallyExclusiveSelections = properlyTypedMultiOption.filter(o =>
                            mutuallyExclusiveValues.includes(o.value.toString())
                          );
                          if (mutuallyExclusiveSelections.length > 0) {
                            onChange(mutuallyExclusiveSelections[mutuallyExclusiveSelections.length - 1].value);
                          } else {
                            onChange(null);
                          }
                        }
                      } else {
                        const mutuallyExclusiveSelections = properlyTypedMultiOption.filter(o =>
                          mutuallyExclusiveValues.includes(o.value.toString())
                        );

                        if (mutuallyExclusiveSelections.length > 0) {
                          setOnMutuallyExclusiveOption(true);
                          onChange([mutuallyExclusiveSelections[mutuallyExclusiveSelections.length - 1].value]);
                        } else {
                          onChange(properlyTypedMultiOption.map(e => e.value));
                        }
                      }
                    } else {
                      onChange(properlyTypedMultiOption.map(e => e.value));
                    }
                  } else if (properlyTypedOption === null || properlyTypedOption.value === "") {
                    onChange(null);
                  } else {
                    onChange(properlyTypedOption.value);
                  }
                }}
                openMenuOnFocus
                options={options}
                placeholder={placeholder ?? intl.formatMessage({ id: "dictionary.selectBestMatch" })}
                styles={simpleInput ? selectStyles() : floatingSelectStyles()}
                tabIndex={tabIndex}
                value={getValue(value)}
              />
            </div>
          )}
        />
      </div>
      {arrayErrors && arrayErrors.length > 0 && (
        <div id={`${name}-errors`}>{arrayErrors?.map(error => <Error error={error} key={error?.message} />)}</div>
      )}
    </>
  );
};

export default Select;
