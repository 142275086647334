import { isPlatform } from "@ionic/react";

export const isIosNativeApp = () => isPlatform("ios") && (isPlatform("cordova") || isPlatform("capacitor"));

export const isAndroidNativeApp = () => isPlatform("android") && (isPlatform("cordova") || isPlatform("capacitor"));

export const isNotNativeApp = () => !isIosNativeApp() && !isAndroidNativeApp();

export const isNativeApp = () => isIosNativeApp() || isAndroidNativeApp();

export const isWebApp = () => isNotNativeApp();

export const isMobileWeb = () => isPlatform("mobileweb");

export const isMobile = () => isPlatform("mobile");
