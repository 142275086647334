import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";

import { isNativeApp } from "@utils/platformUtils";

export const downloadFile = async (uri: string, filename: string) => {
  if (isNativeApp()) {
    const request = new Request(uri);

    const response = await fetch(request);

    const blob = await response.blob();

    if (response) {
      const reader = new FileReader();

      const handleLoad = () => {
        Filesystem.writeFile({
          data: reader.result as string,
          directory: Directory.External,
          path: filename,
          recursive: true
        })
          .then(resultSaveFile => {
            FileOpener.open({ filePath: resultSaveFile.uri });
          })
          .finally(() => {
            reader.removeEventListener("load", handleLoad);
          });
      };

      reader.addEventListener("load", handleLoad, false);

      reader.readAsDataURL(blob);
    }
  } else {
    const link = document.createElement("a");
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
